<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">主页</el-breadcrumb-item>
      <el-breadcrumb-item>日志管理</el-breadcrumb-item>
      <el-breadcrumb-item>VIP日志</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card body-style=" padding: 30px ">
      <el-row :gutter="30">
        <el-form :model="queryinfo" ref="queryinfoRef">
          <el-col :span="6.5">
            <el-form-item prop="queryname">
              <div style="display: inline-block" class="divSpan">
                套餐名称：
              </div>
              <div style="display: inline-block">
                <el-input
                  placeholder="请输入搜索的套餐"
                  v-model.trim="queryinfo.queryname"
                  clearable
                  @clear="getUserLogList"
                >
                </el-input>
              </div>
            </el-form-item>
          </el-col>
        </el-form>
        <el-col :span="2">
          <el-button type="primary" icon="el-icon-search" @click="seachChange"
            >查询</el-button
          >
        </el-col>
      </el-row>
      <el-divider></el-divider>
      <el-row :gutter="30" style="display: flex">
        <el-table
          :data="UserLogList"
          :header-cell-style="{
            textAlign: 'center',
            background: '#FAFAFA',
            color: 'rgba(0, 0, 0, 0.85)',
            fontWeight: '500',
          }"
          tooltip-effect="dark"
          :cell-style="{
            textAlign: 'center',
          }"
        >
          <el-table-column type="index" min-width="50px"></el-table-column>
          <el-table-column
            prop="saveUsername"
            label="操作账号"
            min-width="150px"
          ></el-table-column>
          <el-table-column
            prop="userId"
            label="用户ID"
            min-width="150px"
          ></el-table-column>
          <el-table-column
            prop="username"
            label="用户名"
            min-width="100px"
          ></el-table-column>
          <el-table-column
            prop="phone"
            label="手机号"
            min-width="80px"
          ></el-table-column>
          <el-table-column
            prop="packageKindName"
            label="套餐"
            min-width="100px"
          ></el-table-column>
          <el-table-column
            prop="price"
            label="价格"
            min-width="200px"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="validTime"
            label="会员到期时间"
            min-width="150px"
          ></el-table-column>
          <el-table-column
            prop="sellerName"
            label="销售员"
            min-width="150px"
          ></el-table-column>
          <el-table-column
            prop="createTime"
            label="操作时间"
            min-width="150px"
          ></el-table-column>
        </el-table>
      </el-row>
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryinfo.pageNum"
        :page-size="queryinfo.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
  </div>
</template>
<script>
import { giveVipLog } from '@/api/logmanager/userLog'
export default {
  data () {
    return {
      queryinfo: {
        queryname: '',
        pageNum: 1,
        pageSize: 20
      },
      total: 0,
      UserLogList: []
    }
  },
  mounted () {
    this.getUserLogList()
  },
  methods: {
    async getUserLogList () {
      const res = await giveVipLog(this.queryinfo.pageNum, this.queryinfo.pageSize, this.queryinfo.queryname)
      if (res.status !== 200) { return this.$message.error('获取列表失败') }
      if (res.data.status !== 200) return this.$message.error(res.data.message)
      this.UserLogList = res.data.data.list
      this.total = res.data.data.total
    },
    seachChange () {
      this.queryinfo.pageNum = 1
      this.getUserLogList()
    },
    handleSizeChange (newSize) {
      this.queryinfo.pageSize = newSize
      this.getUserLogList()
    },
    handleCurrentChange (newPage) {
      this.queryinfo.pageNum = newPage
      this.getUserLogList()
    }
  }
}
</script>
